import React from "react"

import Seo from "../../components/seo";
import Layout from "../../components/layout";
import Pricing from '../../layouts/Pricing'; 
import {graphql, useStaticQuery} from 'gatsby';

const PricingPageEn = () => {

  const data = useStaticQuery(graphql`
    query PricingDatasEn {
      allContentfulForfaits(sort: {fields: price, order: ASC}, filter: {node_locale: {eq: "en-CA"}}) {
        edges {
          node {
            name
            inclusions
            price
          }
        }
      }
    }
  `)

  return (
    <Layout 
    lang="en-CA" 
    switchLink='/tarifs' 
    slider={false}
    title='starting at 19$'
    subTitle='The offer you were looking for'>
      <Seo title="Digital Display Cost | Solution Starting at 19$"
           description="What are the costs of digital signage? Learn more on dynamic and interactive digital signage technology, interactive terminals and content management pricing. Solution starting at 19$ per month."
           lang="en-CA"/>
      <Pricing lang="en-CA"  plans={data.allContentfulForfaits.edges}/>
    </Layout>
  )
}

export default PricingPageEn
